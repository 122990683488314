var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "q-form",
          { ref: "editForm" },
          [
            _c(
              "c-table",
              {
                ref: "grid",
                attrs: {
                  title: "지도ㆍ점검을 받은 사항",
                  tableId: "grid",
                  columns: _vm.grid.columns,
                  gridHeight: _vm.grid.height,
                  data: _vm.grid.data,
                  usePaging: false,
                  hideBottom: false,
                  columnSetting: false,
                  filtering: false,
                  isExcelDown: false,
                  editable: _vm.editable,
                  gridHeightAuto: _vm.param.isFullScreen,
                  selection: "multiple",
                  rowKey: "envWaterDailyResultGuidanceId",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        !_vm.param.isFullScreen && _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "LBLADD", icon: "add" },
                              on: { btnClicked: _vm.addRow },
                            })
                          : _vm._e(),
                        !_vm.param.isFullScreen && _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "LBLREMOVE", icon: "remove" },
                              on: { btnClicked: _vm.deleteRow },
                            })
                          : _vm._e(),
                        !_vm.param.isFullScreen && _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "LBLSAVE", icon: "save" },
                              on: { btnClicked: _vm.saveData },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }